<template>
	<div class="page">
        <div class="content">
			<el-form inline class="tb-form" label-width="90px" v-model="searchInfo">
				<el-form-item label="举报选项">
					<el-select v-model="searchInfo.report_type">
						<el-option
                          v-for="item in reportTypeList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="处理状态:">
                    <el-select v-model="searchInfo.status">
                        <el-option
                          v-for="item in statusList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="resetParams">重置</el-button>
                </el-form-item>
			</el-form>
            <div class="tablebox">
                <el-table :data="list" class="tb-table" @row-dblclick="jumpToDetail">
                    <el-table-column prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column prop="feed_id" label="举报动态ID" width="110" show-overflow-tooltip></el-table-column>
                    <el-table-column label="举报类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{scope.row.report_type | getReportTypeName}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="举报理由"></el-table-column>
                    <el-table-column label="处理状态" width="110">
                        <template slot-scope="scope">
                            {{scope.row.status | getDealStatusName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="动态状态" width="110">
                        <template slot-scope="scope">
                            {{scope.row.feed_status | getStatusName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" @click="jumpToDetail(scope.row)">预览</el-button>
                            <el-button type="text" @click="passRow(scope.row)" v-if="scope.row.status === 0">通过</el-button>
                            <el-button type="text" @click="rejectRow(scope.row)" v-if="scope.row.status === 0">拒绝</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="curPage"
                        :page-size="20"
                        :total="totalCount"
                        @current-change="onSearch"
                />
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
                curPage:1,
                totalCount:0,
				searchInfo:{
                    report_type: -1,
					status:-1
				},
                reportTypeList: [
                    {value:-1, name:'全部'},
                    {value:1, name:'广告、垃圾营销或售卖假货等'},
                    {value:2, name:'抄袭或盗用他人内容'},
                    {value:3, name:'违法、色情或涉政内容'},
                    {value:4, name:'造谣或欺诈'},
                    {value:5, name:'辱骂谩骂或不友善'},
                    {value:6, name:'其他'}
                ],
				statusList:[
                    {value:-1,name:'全部'},
					{value:0,name:'未审核'},
                    {value:1,name:'通过'},
					{value:2,name:'拒绝'}],
                list:[],
                selectRow:{}
			}
		},
        mounted() {
          this.onSearch()  
        },
        methods:{
            resetParams() {
                this.curPage = 1
                this.searchInfo.report_type = -1
                this.searchInfo.status = -1
            },
            async getList() {
                const url = "admin/feed/report/list"
                const params = {
                                page_no: this.curPage -1,
                                page_size:20
                                };
                if (this.searchInfo.report_type >= 0) {
                    params.report_type = this.searchInfo.report_type
                }
                if (this.searchInfo.status >= 0) {
                    params.status = this.searchInfo.status
                }
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.list = data.list
                    this.totalCount = data.total_count
                }
            },
            jumpToDetail(row) {
                this.$router.push({path:'/feedReport/edit', query:{id:row.id}})
            },
            passRow(row) {
                this.editStatus(row.id, 1)
            },
            rejectRow(row) {
               this.editStatus(row.id, 2)
            },
            editStatus(id, status) {
                let action = (1 === status) ? '通过' : '拒绝'
                this.$confirm('您确定要' + action + '这条举报吗？', '', {
                    onfirmButtonText: '确定',
                              cancelButtonText: '取消',
                              type: 'warning'
                }).then(() => {
                    const url = 'admin/feed/report/deal'
                    const params = {id: id, status: status}
                    this.$https.post(url, params).then(res => {
                        if (res) this.getList()
                    })
                })
            },
            onSearch() {
                this.getList()
            }
        },
        filters:{
            getReportTypeName(reportType) {
                let reportTypeList = [
                    {value:1, name:'广告、垃圾营销或售卖假货等'},
                    {value:2, name:'抄袭或盗用他人内容'},
                    {value:3, name:'违法、色情或涉政内容'},
                    {value:4, name:'造谣或欺诈'},
                    {value:5, name:'辱骂谩骂或不友善'},
                    {value:6, name:'其他'}
                ]
                return reportTypeList[reportType - 1].name
            },
            getDealStatusName(status){
                let statusList = [
					{value:0,name:'未审核'},
                    {value:1,name:'通过'},
					{value:2,name:'拒绝'}];
                return statusList[status].name
            },
            getStatusName(status) {
                return status === 0 ? '无效' : '有效'
            }
        }
	}
</script>

<style>
</style>
